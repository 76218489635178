import { Link } from "react-router-dom";
import "./Header.scss";
import { useState } from "react";
import { FaFacebookSquare } from "react-icons/fa";

export default function Header() {
  const [NavWidth, setNavWidth] = useState("0");

  const handleOpenNav = (e) => {
    e.preventDefault();
    setNavWidth("250px");
  };

  const handleCloseNav = (e) => {
    e.preventDefault();
    setNavWidth("0px");
  };

  return (
    <header class="header" id="header">
      <nav class="navbar container">
        <a href="/" target="_blank" class="brand">
          <img src="/img/SSR-Movies-2024.png" alt="ssrmovies.solution" />
        </a>
        <div class="burger" id="burger" onClick={handleOpenNav}>
          <span class="burger-line"></span>
          <span class="burger-line"></span>
          <span class="burger-line"></span>
        </div>
        <div class="menu" id="menu">
          <ul class="menu-inner">
            <li class="menu-item">
              <a href="/" target="_blank" class="menu-link">
                All Movies
              </a>
            </li>
            <li class="menu-item">
              <a href="/" target="_blank" class="menu-link">
                Hindi Dubbed Movies
              </a>
            </li>
            <li class="menu-item">
              <a href="/about-us" target="_blank" class="menu-link">
                About us
              </a>
            </li>
            <li class="menu-item">
              <a href="/contact-us" target="_blank" class="menu-link">
                Contact us
              </a>
            </li>
          </ul>
        </div>

        <div
          id="mySidepanel"
          style={{ width: NavWidth, height: "100vh" }}
          class="sidepanel"
        >
          <Link class="closebtn" onClick={handleCloseNav}>
            &times;
          </Link>
          <a href="/" target="_blank">
            All Movies
          </a>
          <a href="/" target="_blank">
            Hindi Dubbed Movies
          </a>
          <a href="/about-us" target="_blank">
            About
          </a>
          <a href="/contact-us" target="_blank">
            Contact Us
          </a>
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
        {/* <Link href="/" class="menu-block">
          Login
        </Link> */}
      </nav>
      <a href="https://www.facebook.com/profile.php?id=61562962967386&mibextid=ZbWKwL" style={{ color: "blue", textAlign: "center" }}>
        <FaFacebookSquare />{" "}
      </a>
    </header>
  );
}

export const CREATE_MOVIE_REQ = "CREATE_MOVIE_REQ";
export const CREATE_MOVIE_SUCCESS = "CREATE_MOVIE_SUCCESS";
export const CREATE_MOVIE_FAILED = "CREATE_MOVIE_FAILED";
export const CREATE_MOVIE_RESET = "CREATE_MOVIE_RESET";

export const UPDATE_MOVIE_REQ = "UPDATE_MOVIE_REQ";
export const UPDATE_MOVIE_SUCCESS = "UPDATE_MOVIE_REQ";
export const UPDATE_MOVIE_FAILED = "UPDATE_MOVIE_REQ";
export const UPDATE_MOVIE_RESET = "UPDATE_MOVIE_REQ";

export const MOVIE_LIST_REQ = "MOVIE_LIST_REQ";
export const MOVIE_LIST_SUCCESS = "MOVIE_LIST_SUCCESS";
export const MOVIE_LIST_FAILED = "MOVIE_LIST_FAILED";
export const MOVIE_LIST_RESET = "MOVIE_LIST_RESET";

export const SINGLE_MOVIE_REQ = "SINGLE_MOVIE_REQ";
export const SINGLE_MOVIE_SUCCESS = "SINGLE_MOVIE_SUCCESS";
export const SINGLE_MOVIE_FAILED = "SINGLE_MOVIE_FAILED";
export const SINGLE_MOVIE_RESET = "SINGLE_MOVIE_RESET";

import {
    applyMiddleware,
    combineReducers,
    compose,
    legacy_createStore,
  } from "redux";
import {MOVIE_LIST_REDUCER} from  "./Reducer/MovieReducer"
import { CONTACT_CREATE_REDUCER } from "./Reducer/ContactReducer";
import { thunk } from "redux-thunk";

  const rootReducers = combineReducers({
    MOVIE_LIST_REDUCER,CONTACT_CREATE_REDUCER

  });

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = legacy_createStore(
  rootReducers,

  composeEnhancers(applyMiddleware(thunk))
);
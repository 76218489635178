import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import MetaData from "../../Components/Meta";
import { useEffect, useState } from "react";
import SearchBar from "../../Components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_MOVIE } from "../../Action/MovieAction";
import Data from "../../Data/MoviesworldX.json";
export default function Index() {
  const [MovieData, setMovieData] = useState({});
  const [NavigateUrlActive, setNavigateUrlActive] = useState(0);
  const dispatch = useDispatch();

  const { MOVIE_List_Loading, MOVIE_List_Error, MOVIE_List, Total_Movies } =
    useSelector((state) => state.MOVIE_LIST_REDUCER);

  const params = useParams();
  const Navigate = useNavigate();
  const mobile_Screen = window.innerWidth;
  // useEffect(() => {
  //   dispatch(LIST_MOVIE({ MovieHeading: params.movieName }));
  // }, [dispatch, params]);

  useEffect(() => {
    if (Data) {
      setMovieData(Data?.find((ev) => ev.MovieHeading === params.movieName));
    }
  }, [Data, params]);

  return (
    <>
      <MetaData
        Meta_title={MovieData?.MovieName}
        Meta_Description={MovieData.Movie_Description1}
        Meta_og_type={"Hindi Movies"}
        Meta_og_url={`https://ssrmovies.solutions/${MovieData.MovieHeading}`}
        Meta_og_title={MovieData.MovieName}
        Meta_og_description={MovieData.Movie_Description2}
        Meta_og_image={MovieData.Poster}
      />
      <div>
        <section>
          <Header />
        </section>
        <section>
          <SearchBar />
        </section>

        <section
          style={{
            padding: "20px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h2
              style={{
                textAlign: "center",
                fontSize: mobile_Screen <= 480 ? "16px" : "22px",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {MovieData?.MovieName}
            </h2>

            <div
              style={
                mobile_Screen <= 480
                  ? { fontSize: "12px" }
                  : { fontSize: "16px" }
              }
              dangerouslySetInnerHTML={{
                __html: MovieData?.Movie_Description1,
              }}
            />
          </div>
          <div>
            <img
              style={{ width: "100%" }}
              src={MovieData?.Poster}
              alt="poster"
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <div
              style={
                mobile_Screen <= 480
                  ? { fontSize: "15px", fontWeight: "bold" }
                  : { fontSize: "18px", fontWeight: "bold" }
              }
              dangerouslySetInnerHTML={{
                __html: MovieData?.Movie_Description2,
              }}
            />
          </div>
          <div>
            <img
              style={{ width: "100%" }}
              src={MovieData?.Movie_ScreenShot}
              alt="screenshot"
            />
          </div>
        </section>
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              if (NavigateUrlActive !== 2) {
                setNavigateUrlActive(NavigateUrlActive + 1);
                window.open(
                  "https://www.highrevenuenetwork.com/q7pf0xfjt?key=0701a6cad92ba80d8e1e39af62f265c8",
                  "_blank"
                );
              } else {
                window.open(`/MovieDownload/${MovieData?.MovieHeading}`);
              }
            }}
            className="hoverBtn1"
            style={{ borderRadius: "10px", padding: "10px 20px" }}
          >
            Download 480P
          </button>

          <button
            className="hoverBtn2"
            onClick={() => {
              if (NavigateUrlActive !== 2) {
                setNavigateUrlActive(NavigateUrlActive + 1);
                window.open(
                  "https://www.highrevenuenetwork.com/q7pf0xfjt?key=0701a6cad92ba80d8e1e39af62f265c8",
                  "_blank"
                );
              } else {
                window.open(`/MovieDownload/${MovieData?.MovieHeading}`);
              }
            }}
            style={{ borderRadius: "10px", padding: "10px 20px" }}
          >
            Download 720P
          </button>

          <button
            className="hoverBtn3"
            onClick={() => {
              if (NavigateUrlActive !== 2) {
                setNavigateUrlActive(NavigateUrlActive + 1);
                window.open(
                  "https://www.highrevenuenetwork.com/q7pf0xfjt?key=0701a6cad92ba80d8e1e39af62f265c8",
                  "_blank"
                );
              } else {
                window.open(`/MovieDownload/${MovieData?.MovieHeading}`);
              }
            }}
            style={{ borderRadius: "10px", padding: "10px 20px" }}
          >
            Download 1080P
          </button>
        </div>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}

import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import MetaData from "../../Components/Meta";

export default function Index() {
  return (
    <>
      <MetaData
        Meta_title={"Privacy Policy"}
        Meta_Description={"SSRmovies Privacy Policy Details"}
        Meta_og_type={"Hindi Movies"}
        Meta_og_url={`https://ssrmovies.solutions/privacy-policy`}
        Meta_og_title={"SSRmovies Privacy Policy Details"}
        Meta_og_description={
          "SSRmovies Privacy Policy Details and It is not promoting any kind of pirarcy."
        }
        Meta_og_image={""}
      />
      <div>
        <div>
          <section>
            <Header />
          </section>

          <section class="flex items-center bg-stone-100 xl:h-screen font-poppins dark:bg-gray-800 ">
            <div class="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
              {/* <div class="flex flex-wrap "> */}
              {/* <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0"> */}
              {/* <div class="relative lg:max-w-md"> */}

              {/* </div> */}
              {/* </div> */}
              <div class="w-full px-6 mb-10  lg:mb-0 ">
                <div class="pl-4 mb-6 border-l-4 border-blue-500 ">
                  <span class="text-sm text-gray-600 uppercase dark:text-gray-400"></span>
                  <h1 class="mt-2 text-3xl font-black text-gray-700 md:text-5xl dark:text-gray-300">
                    Privacy Policy
                  </h1>
                </div>
                <p class="mb-6 text-base leading-7 text-gray-500 dark:text-gray-400">
                  We never sell your personal information to third parties. We
                  may use your IP address to help diagnose any problem with our
                  service and to provide better services for our visitors. We
                  use third party advertising companies to serve ads when you
                  visit our website. The ads may contain cookies which may be
                  used by the advertising companies to better understand a
                  visitor’s preferences and help them to display related ads.
                  However, the cookies received with the advertisements are
                  collected by the advertising company and we do not have access
                  to that information.These companies may use information (not
                  including your name, address, email address, or telephone
                  number) about your visits to this and other websites in order
                  to provide advertisements about goods and services of interest
                  to you. Email Addresses – We never share your email addresses
                  and any other valuable details with anyone. Log Files – As
                  with most other websites, we collect and use the data
                  contained in log files. The information in the log files
                  include your IP (internet protocol) address, your ISP (airtel,
                  bsnl, mtnl, tata etc…), the browser you used to visit our site
                  (such as Internet Explorer or Firefox), the time you visited
                  our site and which pages you visited throughout our site.
                  Cookies and Web Beacons – We use third-party advertising
                  companies to serve ads when you visit our website. These
                  companies may use information (not including your name,
                  address, email address or telephone number) about your visits
                  to this and other websites in order to provide advertisements
                  about goods and services of interest to you. If you would like
                  more information about this practice and would like to know
                  your options in relation to·not having this information used
                  by these companies.
                </p>
                <a
                  href="/"
                  target="_blank"
                  class="px-4 py-2 text-gray-100 bg-blue-500 rounded dark:bg-blue-400 dark:hover:bg-blue-500 hover:bg-blue-600"
                >
                  Go to Home Page
                </a>
              </div>
              {/* </div> */}
            </div>
          </section>

          <section>
            <Footer />
          </section>
        </div>
      </div>
    </>
  );
}

import { CREATE_CONTACT_ERROR, CREATE_CONTACT_REQ, CREATE_CONTACT_RESET, CREATE_CONTACT_SUCCESS } from "../Constatnt/ContactConstant";




export const CONTACT_CREATE_REDUCER=(state={},{type,payload})=>{
  switch(type){


    case CREATE_CONTACT_REQ:{
        return {Contact_Create_Loading:true};
    }

    
    case CREATE_CONTACT_SUCCESS:{
        return {Contact_Create_Loading:false,Contact_Create_Success:payload}
    }

    case CREATE_CONTACT_ERROR:{
        return {Contact_Create_Loading:false,Contact_Create_Error:payload}
    }

    case CREATE_CONTACT_RESET:{
        return {}
    }
    default:return state;
  }
}
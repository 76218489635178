import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SearchBar from "../../Components/SearchBar";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LIST_MOVIE } from "../../Action/MovieAction";
import { useSelector } from "react-redux";
import Pagination from "../../Components/Pagination";
import { Movie_Query } from "../../Model/data";
import MetaData from "../../Components/Meta";
import Data from "../../Data/MoviesworldX.json";
export default function Index() {
  const Navigate = useNavigate();
  const [Movies, setMovies] = useState([]);
  const [MoviesQuery, setMoviesQuery] = useState(Movie_Query);
  const [ActivePage, setActivePage] = useState(1);
  const [Search, setSearch] = useState({ Movie_Title: "" });
  const dispatch = useDispatch();
  const { MOVIE_List_Loading, MOVIE_List_Error, MOVIE_List, Total_Movies } =
    useSelector((state) => state.MOVIE_LIST_REDUCER);

  const GET_MOVIES = useCallback(() => {
    // dispatch(LIST_MOVIE(MoviesQuery));
    // {movieName:"",page_no:1,page_size:8}

    let p = (MoviesQuery?.page_no - 1) * MoviesQuery?.page_size;
    let c = 0;

    let MoviesDatas = Data?.filter((ev, i) => {
      // if(Movie_Query?.movieName){
      //   if(ev.MovieName.match("(/^([a-z0-9]{5,})$/)"))
      // }
      if (c <= MoviesQuery?.page_size && i >= p) {
        c++;
        return ev;
      }
    });

    setMovies(MoviesDatas);
  }, [dispatch, MoviesQuery, Search]);

  useEffect(() => {
    if (MOVIE_List?.length) {
      setMovies(MOVIE_List);
    }
  }, [MOVIE_List]);

  useEffect(() => {
    GET_MOVIES();
  }, [GET_MOVIES]);

  useEffect(() => {
    setMoviesQuery({ ...MoviesQuery, page_no: ActivePage });
  }, [ActivePage]);

  return (
    <>
      <MetaData
        Meta_title={"SSRmovies Movies is a blogs for movie details"}
        Meta_Description={"SSRmovies Movies is a blogs for movie details"}
        Meta_og_type={"Hindi Movies"}
        Meta_og_url={`https://ssrmovies.solutions/`}
        Meta_og_image={"./img/SSR-Movies-2024.png"}
      />
      <div>
        <section>
          <Header />
        </section>
        <section style={{ background: "white" }}>
          <SearchBar
            setMoviesQuery={setMoviesQuery}
            MoviesQuery={MoviesQuery}
            Search={Search}
            setSearch={setSearch}
          />
        </section>
        <section className="Home">
          {Movies?.map((el, i) => (
            <div
              key={i}
              onClick={() => window.open(`/${el.MovieHeading}`, "_blank")}
              className="MovieCard"
            >
              <img src={el.Poster} alt="poster" />
              <p>{el.MovieName}</p>
            </div>
          ))}
        </section>
        {/*  */}
        <section>
          <Pagination
            ActivePage={ActivePage}
            setActivePage={setActivePage}
            Total_pages={Data?.length}
          />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}

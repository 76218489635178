import {
    MOVIE_LIST_FAILED,
    MOVIE_LIST_REQ,
    MOVIE_LIST_SUCCESS,
  } from "../Constatnt/MovieConstant";
  
  export const MOVIE_LIST_REDUCER = (state = {}, { type, payload }) => {
    switch (type) {
      case MOVIE_LIST_REQ: {
        return { MOVIE_List_Loading: true };
      }
  
      case MOVIE_LIST_SUCCESS: {
        return { MOVIE_List_Loading: false, MOVIE_List: payload.Result,Total_Movies:payload?.totalMovies };
      }
      case MOVIE_LIST_FAILED: {
        return { MOVIE_List_Loading: false, MOVIE_List_Error: payload };
      }
  
      default:
        return state;
    }
  };
  

import { IoSearch } from "react-icons/io5";

export default function SearchBar({Search,setSearch,setMoviesQuery,MoviesQuery}) {
  return (
    <div className="wrap">
      <div className="search">
        <input
          type="text"
          className="searchTerm"
          name='Movie_Title'
          value={Search?.Movie_Title}
          onChange={(e)=>{ if(e.target.value===""){ 

            setMoviesQuery({...MoviesQuery,MovieName:e.target.value})
          }  setSearch({Movie_Title:e.target.value})}}
          placeholder="What are you looking Movies ?"
        />
        <button onClick={()=>setMoviesQuery({...MoviesQuery,MovieName:Search.Movie_Title})}  type="submit" className="searchButton">
          <IoSearch />
        </button>
      </div>
    </div>
  );
}

export const Movie_Data = [
  {
    MovieName: "Salaar 2023 V3 Hindi (Cleaned) 1080p 720p 480p HQ HDTC x264",
    Poster:
      "https://imgurworld.top/images/2023/12/23/f8777fe38d4ddc77d2fe924b9514b86d.jpg",
    Movie_ScreenShot:
      "https://imgurworld.top/images/2023/12/23/14c28ab9600503efa379dadb81d0ceee.jpg",
    MovieHeading: "Salaar-2023-V3-Hindi-(Cleaned)-1080p-720p-480p-HQ-HDTC-x264",
    Movie_Description2: `<h5>Salaar (2023) V3 1080p 720p 480p HQ HDTC x264</h5> <h5>IMDb: N/A/10 || Size: See below ▼ || Language: Hindi (Cleaned)</h5><p>Genres: Action, Drama, Thriller</p><p>Director: Prashanth Neel</p> <p>Writers: Sandeep Reddy Bandla, Choudary Hanuman, Prashanth Neel</p> <p>Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan,</p><p>A gang leader tries to keep a promise made to his dying friend and takes on the other criminal gangs.</p> `,
    Movie_Description1:
      "Salaar full movie free download and watch online in 480p 720p and 1080p HQ HDTC x264 best HD quality. Salaar is available in Hindi (Cleaned) audio track(s) and this one of the best Genres: Action, Drama, Thriller based film which is released in year 2023. In this movie lead roles played by Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan. Enjoy this stuff and explore the 20,000+ various categories movies collection on MoviesWorldX.",
    Download_Link: "",
  },
  {
    MovieName: "Salaar 2023 V3 Hindi (Cleaned) 1080p 720p 480p HQ HDTC x264",
    Poster:
      "https://imgurworld.top/images/2023/12/23/f8777fe38d4ddc77d2fe924b9514b86d.jpg",
    Movie_ScreenShot:
      "https://imgurworld.top/images/2023/12/23/14c28ab9600503efa379dadb81d0ceee.jpg",
    MovieHeading: "Salaar-2023-V3-Hindi-(Cleaned)-1080p-720p-480p-HQ-HDTC-x264",
    Movie_Description2: `<h5>Salaar (2023) V3 1080p 720p 480p HQ HDTC x264</h5> <h5>IMDb: N/A/10 || Size: See below ▼ || Language: Hindi (Cleaned)</h5><p>Genres: Action, Drama, Thriller</p><p>Director: Prashanth Neel</p> <p>Writers: Sandeep Reddy Bandla, Choudary Hanuman, Prashanth Neel</p> <p>Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan,</p><p>A gang leader tries to keep a promise made to his dying friend and takes on the other criminal gangs.</p> `,
    Movie_Description1:
      "Salaar full movie free download and watch online in 480p 720p and 1080p HQ HDTC x264 best HD quality. Salaar is available in Hindi (Cleaned) audio track(s) and this one of the best Genres: Action, Drama, Thriller based film which is released in year 2023. In this movie lead roles played by Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan. Enjoy this stuff and explore the 20,000+ various categories movies collection on MoviesWorldX.",
    Download_Link: "",
  },
  {
    MovieName: "Salaar 2023 V3 Hindi (Cleaned) 1080p 720p 480p HQ HDTC x264",
    Poster:
      "https://imgurworld.top/images/2023/12/23/f8777fe38d4ddc77d2fe924b9514b86d.jpg",
    Movie_ScreenShot:
      "https://imgurworld.top/images/2023/12/23/14c28ab9600503efa379dadb81d0ceee.jpg",
    MovieHeading: "Salaar-2023-V3-Hindi-(Cleaned)-1080p-720p-480p-HQ-HDTC-x264",
    Movie_Description2: `<h5>Salaar (2023) V3 1080p 720p 480p HQ HDTC x264</h5> <h5>IMDb: N/A/10 || Size: See below ▼ || Language: Hindi (Cleaned)</h5><p>Genres: Action, Drama, Thriller</p><p>Director: Prashanth Neel</p> <p>Writers: Sandeep Reddy Bandla, Choudary Hanuman, Prashanth Neel</p> <p>Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan,</p><p>A gang leader tries to keep a promise made to his dying friend and takes on the other criminal gangs.</p> `,
    Movie_Description1:
      "Salaar full movie free download and watch online in 480p 720p and 1080p HQ HDTC x264 best HD quality. Salaar is available in Hindi (Cleaned) audio track(s) and this one of the best Genres: Action, Drama, Thriller based film which is released in year 2023. In this movie lead roles played by Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan. Enjoy this stuff and explore the 20,000+ various categories movies collection on MoviesWorldX.",
    Download_Link: "",
  },
  {
    MovieName: "Salaar 2023 V3 Hindi (Cleaned) 1080p 720p 480p HQ HDTC x264",
    Poster:
      "https://imgurworld.top/images/2023/12/23/f8777fe38d4ddc77d2fe924b9514b86d.jpg",
    Movie_ScreenShot:
      "https://imgurworld.top/images/2023/12/23/14c28ab9600503efa379dadb81d0ceee.jpg",
    MovieHeading: "Salaar-2023-V3-Hindi-(Cleaned)-1080p-720p-480p-HQ-HDTC-x264",
    Movie_Description2: `<h5>Salaar (2023) V3 1080p 720p 480p HQ HDTC x264</h5> <h5>IMDb: N/A/10 || Size: See below ▼ || Language: Hindi (Cleaned)</h5><p>Genres: Action, Drama, Thriller</p><p>Director: Prashanth Neel</p> <p>Writers: Sandeep Reddy Bandla, Choudary Hanuman, Prashanth Neel</p> <p>Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan,</p><p>A gang leader tries to keep a promise made to his dying friend and takes on the other criminal gangs.</p> `,
    Movie_Description1:
      "Salaar full movie free download and watch online in 480p 720p and 1080p HQ HDTC x264 best HD quality. Salaar is available in Hindi (Cleaned) audio track(s) and this one of the best Genres: Action, Drama, Thriller based film which is released in year 2023. In this movie lead roles played by Stars: Prabhas, Prithviraj Sukumaran, Shruti Haasan. Enjoy this stuff and explore the 20,000+ various categories movies collection on MoviesWorldX.",
    Download_Link: "",
  },
];

export const Movie_Query = {
  movieName: "",
  page_no: 1,
  page_size: window.innerWidth <= 480 ? 8 : 15,
};

import { FaFacebookSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="Footer">
      <div>
        <div>
          <p>
            {" "}
            <a href="/" target="_blank">
              {" "}
              SSRmovies{" "}
            </a>{" "}
          </p>
          <p>
            <a
              href="https://www.facebook.com/profile.php?id=61562962967386&mibextid=ZbWKwL"
              style={{ color: "blue", textAlign: "center" }}
            >
              <FaFacebookSquare />{" "}
            </a>
          </p>
        </div>
        <div>
          <p>
            <a href="/privacy-policy" target="_blank">
              {" "}
              Privacy Policy{" "}
            </a>{" "}
          </p>
          <hr />
          <p>
            <a href="/contact-us" target="_blank">
              {" "}
              Contact us
            </a>{" "}
          </p>
          <hr />
          <p>
            {" "}
            <a href="/about-us" target="_blank">
              About us{" "}
            </a>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
}

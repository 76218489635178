import { CREATE_CONTACT_ERROR, CREATE_CONTACT_REQ, CREATE_CONTACT_RESET, CREATE_CONTACT_SUCCESS } from "../Constatnt/ContactConstant"
import axiosConfig from "../Core/axiosConfig"




export const CONTACT_CREATE=(ContactDetails)=>async(dispatch)=>{
    try{
        dispatch({type:CREATE_CONTACT_REQ})
        const {data}=await axiosConfig.post("/contact",ContactDetails);
        dispatch({type:CREATE_CONTACT_SUCCESS,payload:data.Result})

    }catch(ex){
      
        dispatch({type:CREATE_CONTACT_ERROR,payload:ex.message})
    }
}

export const CONTACT_RESET=()=>(dispatch)=>{
    dispatch({type:CREATE_CONTACT_RESET})
}
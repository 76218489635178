import { Helmet } from "react-helmet";

export default function MetaData({
  Meta_title,
  Meta_Description,
  Meta_og_type,
  Meta_og_url,
  Meta_og_title,
  Meta_og_description,
  Meta_og_image,
  Meta_twitter_card,
  Meta_twitter_url,
  Meta_twitter_title,
  Meta_twitter_description,
  Meta_twitter_image,
}) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Meta_title}</title>
        <meta name="description" content={Meta_Description} />
        <meta property="og:type" content={Meta_og_type} />
        <meta property="og:url" content={Meta_og_url} />
        <meta property="og:title" content={Meta_og_title} />
        <meta property="og:description" content={Meta_og_description} />
        <meta property="og:image" content={Meta_og_image} />
        <meta
          name="keywords"
          content= "ssrmovies, Moviesworlx, ssrmovies.com, ssrmovies, all types of movie download, hindi dubbed movies, download movie in hindi, movie downloading website, free movie download, hd movie download, 4k movie download free, latest movies download, download hindi movies"
        />
        {/* <meta name="twitter:card" content={Meta_twitter_card} />
        <meta name="twitter:url" content={Meta_twitter_url} />
        <meta name="twitter:title" content={Meta_twitter_title} />
        <meta name="twitter:description" content={Meta_twitter_description} />
        <meta name="twitter:image" content={Meta_twitter_image} /> */}
      </Helmet>
    </div>
  );
}

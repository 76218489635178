import NavigationRoute from "./Routes/NavigationRoute";
import "./Css/MyCss.css";
function App() {
  return (
    <div>
      <NavigationRoute />
    </div>
  );
}

export default App;

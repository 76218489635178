import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import MetaData from "../../Components/Meta";
import { useEffect, useState } from "react";
import SearchBar from "../../Components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_MOVIE } from "../../Action/MovieAction";
import Data from "../../Data/MoviesworldX.json";
export default function Index() {
  const [NavigateUrlActive, setNavigateUrlActive] = useState(0);
  const [MovieData, setMovieData] = useState({});
  const params = useParams();
  const { MOVIE_List_Loading, MOVIE_List_Error, MOVIE_List, Total_Movies } =
    useSelector((state) => state.MOVIE_LIST_REDUCER);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  // useEffect(() => {
  //   dispatch(LIST_MOVIE({ MovieHeading: params.movieName }));
  // }, [dispatch, params]);

  useEffect(() => {
    if (Data) {
      setMovieData(Data?.find((ev) => ev.MovieHeading === params.movieName));
    }
  }, [Data, params]);

  return (
    <>
      <MetaData
        Meta_title={MovieData.MovieName}
        Meta_Description={MovieData.Movie_Description1}
        Meta_og_type={"Hindi Movies"}
        Meta_og_url={`https://ssrmovies.solutions/${MovieData.MovieHeading}`}
        Meta_og_title={MovieData.MovieHeading}
        Meta_og_description={""}
        Meta_og_image={MovieData.Poster}
      />
      <div>
        <section>
          <Header />
        </section>
        <section>
          <SearchBar />
        </section>

        <section
          style={{
            padding: "20px 20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h2
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {MovieData?.MovieName}
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: MovieData?.Movie_Description1,
              }}
            />
          </div>

          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="hoverBtn"
              onClick={() => {
                if (NavigateUrlActive !== 2) {
                  setNavigateUrlActive(NavigateUrlActive + 1);
                  window.open(
                    "https://www.highrevenuenetwork.com/w40bumdx39?key=08bce2dd01c7fff6e62e89bd4b031f58",
                    "_blank"
                  );
                } else {
                  window.open(`/`);
                }
              }}
              style={{ borderRadius: "10px", padding: "10px 20px" }}
            >
              Download
            </button>
          </div>
          <div>
            <img
              style={{ width: "100%" }}
              src={MovieData?.Poster}
              alt="poster"
            />
          </div>

          <div style={{ textAlign: "center" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: MovieData?.Movie_Description2,
              }}
            />
          </div>
        </section>

        <div style={{ padding: "20px" }}>
          <iframe
            title={MovieData?.MovieName}
            id="video1"
            width="520"
            height="360"
            src={MovieData?.Movie_Trailer_Link}
            frameborder="0"
            allowtransparency="true"
            allowfullscreen
          ></iframe>
          <a href="#" id="playvideo">
            Play video
          </a>
        </div>

        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="hoverBtn"
            onClick={() => {
              if (NavigateUrlActive !== 2) {
                setNavigateUrlActive(NavigateUrlActive + 1);
                window.open(
                  "https://www.highrevenuenetwork.com/w40bumdx39?key=08bce2dd01c7fff6e62e89bd4b031f58",
                  "_blank"
                );
              } else {
                window.open(`/`, "_blank");
              }
            }}
            style={{ borderRadius: "10px", padding: "10px 20px" }}
          >
            Download
          </button>
        </div>

        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}

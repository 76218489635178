import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_CREATE, CONTACT_RESET } from "../../Action/ContactAction";
import MetaData from "../../Components/Meta";

export default function Index() {
  const [ContactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    message: "",
  });
  const dispatch = useDispatch();
  const {
    Contact_Create_Loading,
    Contact_Create_Success,
    Contact_Create_Error,
  } = useSelector((state) => state.CONTACT_CREATE_REDUCER);
  const handleChange = (e) => {
    setContactDetails({ ...ContactDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(CONTACT_CREATE(ContactDetails));
  };

  useEffect(() => {
    if (Contact_Create_Success) {
      setTimeout(() => {
        dispatch(CONTACT_RESET());
      }, 5000);

      setContactDetails({ name: "", email: "", message: "" });
    }
  }, [Contact_Create_Success, dispatch]);

  return (
    <>
      <MetaData
        Meta_title={"Contact Us"}
        Meta_Description={
          " SSRmovies is movie blogging website. It will provide all types movies details like hindi movies, hollywood movies, tollywood movies and telugu and tamil movies also. It will give you all details of movie creating and rating budget.Contact us with fill this form"
        }
        Meta_og_type={"Hindi Movies"}
        Meta_og_url={`https://SSRmovies.solutions/contact-us`}
        Meta_og_title={"Contact Us"}
        Meta_og_description={
          " SSRmovies is movie blogging website. It will provide all types movies details like hindi movies, hollywood movies, tollywood movies and telugu and tamil movies also. It will give you all details of movie creating and rating budget."
        }
        Meta_og_image={""}
      />
      <div>
        <div>
          <section>
            <Header />
          </section>

          <section
            style={{ padding: "40px 0" }}
            class="text-gray-600 body-font bg-yellow-100"
          >
            <div
              class="container flex flex-col md:flex-row lg:max-w-5xl w-full px-5 py-12 md:py-24 mx-auto section"
              id="contact-form"
            >
              <div class="md:w-1/3 w-full">
                <h1 class="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">
                  Contact Us
                </h1>
                <p class="leading-relaxed text-xl text-gray-900">
                  We're here to assist you! If you have any questions or need
                  assistance, please feel free to reach out to us.
                  <br />
                  You can also email us. Click here to reveal email address
                  <a
                    href="mailto:mohanty.ryzen@gmail.com"
                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    mohanty.ryzen@gmail.com
                  </a>
                </p>
                <p class="leading-relaxed text-xl text-gray-900 mt-8">
                  We use VeilMail.io to protect your email address from spam.
                </p>
                <span class="inline-flex mt-6 justify-center sm:justify-start"></span>
              </div>
              <div class="md:w-2/3 w-full mt-10 md:mt-0 md:pl-28">
                <h1 class="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">
                  Contact Form
                </h1>
                <form
                  action="send-contact.php"
                  onSubmit={handleSubmit}
                  id="submit-contact-form"
                >
                  <div class="p-2 w-full">
                    <div class="relative">
                      <label
                        for="name"
                        class="leading-7 py-4 text-lg text-gray-900"
                      >
                        Your Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={ContactDetails.name}
                        onChange={handleChange}
                        name="name"
                        required
                        class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out "
                      />
                    </div>
                  </div>
                  <div class="p-2 w-full">
                    <div class="relative">
                      <label
                        for="email"
                        class="leading-7 py-4 text-lg text-gray-900"
                      >
                        Your Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={ContactDetails.email}
                        onChange={handleChange}
                        required
                        class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-900 py-1 px-1 leading-8 transition-colors duration-200 ease-in-out "
                      />
                    </div>
                  </div>
                  <div class="p-2 w-full">
                    <div class="relative">
                      <label
                        for="message"
                        class="leading-7 py-4 text-lg text-gray-900"
                      >
                        Your Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={ContactDetails.message}
                        onChange={handleChange}
                        required={true}
                        class="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-900 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out "
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <p
                      style={
                        Contact_Create_Success
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {Contact_Create_Success || Contact_Create_Error}
                    </p>
                  </div>
                  <div class="p-2 w-full">
                    <button
                      type="submit"
                      class="flex text-white bg-gray-900 border-0 py-4 px-6 focus:outline-none hover:bg-blue-900 rounded text-xl font-bold shadow-lg mx-0 flex-col text-center g-recaptcha"
                    >
                      {Contact_Create_Loading
                        ? "Loading ..."
                        : " Send Message ✉"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>

          <section>
            <Footer />
          </section>
        </div>
      </div>{" "}
    </>
  );
}

import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Data from "../../Data/MoviesworldX.json";

export default function Index() {
  return (
    <>
      <div>
        <section>
          <Header />
        </section>
        <section style={{ width: "70%", margin: "auto", padding: "20px" }}>
          <a
            href={`https://ssrmovies.solutions`}
            rel="noreferrer"
            target="_blank"
          >
            SSRmovies
          </a>

          {Data?.map((ev) => (
            <div>
              <a
                href={`https://ssrmovies.solutions/${ev.MovieHeading}`}
                rel="noreferrer"
                target="_blank"
                style={{ width: "90%", margin: "auto", overflow: "hidden" }}
              >
                /{ev.MovieHeading}
              </a>
            </div>
          ))}

          {Data?.map((ev) => (
            <div>
              <a
                href={`https://ssrmovies.solutions/MovieDownload/${ev.MovieHeading}`}
                rel="noreferrer"
                target="_blank"
                style={{ width: "90%", margin: "auto", overflow: "hidden" }}
              >
                /MovieDownload/{ev.MovieHeading}
              </a>
            </div>
          ))}
          <br />

          <a
            href={`https://ssrmovies.solutions/privacy-policy`}
            rel="noreferrer"
            target="_blank"
          >
            /privacy-policy
          </a>
          <br />
          <a
            href={`https://ssrmovies.solutions/contact-us`}
            rel="noreferrer"
            target="_blank"
          >
            /contact-us
          </a>
          <br />
          <a
            href={`https://ssrmovies.solutions/about-us`}
            rel="noreferrer"
            target="_blank"
          >
            /about-us
          </a>

          {/* {Data?.map(
            (ev) => `<url>
        <loc>https://ssrmovies.solutions/${ev.MovieHeading}</loc>
        <lastmod>2024-07-01T07:55:00+01:00</lastmod>
        <priority>1.0</priority>
    </url>`
          )}

          {Data?.map(
            (ev) => `<url>
        <loc>https://ssrmovies.solutions/MovieDownload/${ev.MovieHeading}</loc>
        <lastmod>2024-07-01T07:55:00+01:00</lastmod>
        <priority>1.0</priority>
    </url>`
          )} */}
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
}

import { Routes, Route } from "react-router-dom";
import Home from "../Screens/Home/Index";
import ContactUs from "../Screens/ContactUs/Index";
import AboutUs from "../Screens/AboutUs/Index";
import MoviePage from "../Screens/MoviePage/Index";
import PrivacyPolicy from "../Screens/PrivacyPolicy/Index";
import MovieDownloadPage from "../Screens/DownloadMovie/Index";
import SiteMap from "../Screens/SiteMaps/Index";

export default function NavigationRoute() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:movieName" element={<MoviePage />} />
      <Route path="/MovieDownload/:movieName" element={<MovieDownloadPage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/sitemap" element={<SiteMap />} />
    </Routes>
  );
}

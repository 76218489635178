import { useState } from "react";
import {
    BsChevronLeft,
    BsChevronRight,
   
  } from "react-icons/bs";

export default function Pagination ({Total_pages,ActivePage,setActivePage}){

const mobile_Screen=window.innerWidth
const Pages= Array.from({ length: Math.ceil(Total_pages/ (mobile_Screen>=480 ? 15 :  8)) }, (_, index) => index + 1);

const handlePage=(data)=>{
    setActivePage(data)
}
    return ( <div className="Pagination">
        <div>
          <hr />
        </div>
        <div>
          <p  onClick={()=>{ if(ActivePage!==1) setActivePage(ActivePage-1)}} className="Page">
            <BsChevronLeft />
          </p>
          {Pages?.map((el,i)=>   <p  key={i} style={i >= ActivePage - 3 && i <= ActivePage + 2 ? {display:"block"} : {display:"none"}}  onClick={()=>handlePage(el)} className={ActivePage===el ?  "active":"Page"}>{el}</p> )}
          <p  onClick={()=>{if(ActivePage!==Pages.length)  setActivePage(ActivePage+1)}} className="Page">
            <BsChevronRight />
          </p>
        </div>
        <div>
          <hr />
        </div>
      </div>)
}